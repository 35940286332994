import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";

import { useEffect, useState, useRef } from "react";
import {
  setDoc,
  collection,
  doc,
  onSnapshot,
  getDocs,
  query,
  where,
  addDoc,
  orderBy
} from "firebase/firestore";
import { format } from 'date-fns';

import SnackbarMsg from '../Commons/snackbarMsg';
import {getComplaintDocId, isEmptyObject, queryStringToObject} from '../Utility';
import NoRecordsFound from "../layout/NoRecordsFound";
import { db } from "../firebaseConfig";
import AppSubHeader from "../Commons/appSubHeader";
import LoadingButton from "../Commons/LoadingButton";

const initialSnackbarData = {message: '', open: false, severity: "success" }

const MyComplaints = () => {
  const searchParams = queryStringToObject(window.location.search);
  const [issueData, setIssueData] = useState({
    issueTrackingId: searchParams.bookingId || '',
    issueText: ''
  });
  const [issues, setIssues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(initialSnackbarData);
  const listenerUnsubscribe = useRef(null);

  const userData = JSON.parse(localStorage.getItem("user"));

  const getActiveIssues = async() => {
    setIsLoading(true);
    const reqRef = doc(db, "complaints", `${userData.phone}_${'*'}`);

    const q = query(collection(db, "complaints"), where("userId", "==", `${userData.phone}`));
    // let data = await getDocs(q);
    // console.log('getReq 2 ', data)
    console.log('getActiveIssues ', q, reqRef)

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        // if (!snapshot.exists()) return;
        let data = snapshot.docs;
        console.log("getActiveIssues got issues data ", data, snapshot);
        let sortedData = data.map(i => i.data()).sort((a, b) => (b.createdAt - a.createdAt));
        setIssues(sortedData);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        console.log("view issues error", reqRef, error);
      }
    );
    listenerUnsubscribe.current = unsubscribe;
  }

  useEffect(() => {
    let unsubscribe = () => {};
    const getActiveIssues = async() => {
      setIsLoading(true);
      const reqRef = doc(db, "complaints", `${userData.phone}_${'*'}`);

      const q = query(collection(db, "complaints"), where("userId", "==", `${userData.phone}`));
      // let data = await getDocs(q);
      // console.log('getReq 2 ', data)
      // console.log('getActiveIssues ', q, reqRef)

      unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          // if (!snapshot.exists()) return;
          let data = snapshot.docs;
          console.log("getActiveIssues got issues data ", data, snapshot);
          let sortedData = data.map(i => i.data()).sort((a, b) => (b.createdAt - a.createdAt));
          setIssues(sortedData);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          console.log("view issues error", reqRef, error);
        }
      );
    }

    return () => unsubscribe();
  }, []);

  const handleIssueChange = (field, val) => {
    if( !field ) return;
    setIssueData({
      ...issueData,
      [field]: val
    })
  }

  const handleSubmitIssue = async () => {
    console.log('handleSubmitIssue ', issueData);
    if(isEmptyObject(issueData)) {
      setShowSnackbar({open: true, message: 'Please fill the mandatory details.', severity:'error'})
    }
    let { issueTrackingId, issueText } = issueData || {};
    if(!issueTrackingId || !issueText) {
      // TODO: show error
      return;
    }
    let issueFinalData = {
      userId: userData.phone,
      issueTrackingId,
      issueText,
      createdAt: Date.now(),
      status: 'active'
    }
    let docRef = await addDoc(collection(db, "complaints"), issueFinalData);
    console.log('handleSubmitIssue ', issueData);
    setIssueData({
      issueTrackingId:"",
      issueText:""
    });
    setShowSnackbar({open: true, message: 'Complaint submitted. Our team will reach out to you within 12 hrs regarding the concern.'})
    setTimeout(() => window.location.reload(), 2000)
    // window.reload();
    // navigate("/")
  }


  console.log('complaint render ', issueData, issueData.issueTrackingId)
  return (
    <>
      <Box sx={{ marginBottom: 10 }}>
        <Container>
          <Box
            sx={{
              border: "1px solid #eae7e7",
              background: "#fff",
              marginBottom: 2,
              padding: "1em",
            }}
          >
            <AppSubHeader name="Raise Complaint"></AppSubHeader>
            <p>
              We apologize for the inconvenience you're experiencing with our
              product. Kindly share your booking/request ID and provide details of your complaint
              so that we can assist you promptly.
            </p>

            <TextField
              id="booking id"
              label="Booking Id/Requirement Id/Tracking Id"
              placeholder="Please provide your booking or requirement id"
              variant="outlined"
              required
              fullWidth
              sx={{ mb: 2 }}
              autoFocus
              value={issueData.issueTrackingId}
              onChange={(e) => handleIssueChange('issueTrackingId', e.target.value)}
            />
            <TextField
              id="complaint"
              label="What's your concern?"
              multiline
              placeholder="Please provide some more additional details. We're Here to Listen and Resolve"
              maxRows={14}
              minRows={5}
              fullWidth
              required
              value={issueData.issueText}
              onChange={(e) => handleIssueChange('issueText', e.target.value)}
            />

            <LoadingButton
              sx={{ mt: 2, width: "40%" }}
              loading={false}
              onClick={handleSubmitIssue}
            >
              Submit
            </LoadingButton>
          </Box>

          {/* My Complaints Section */}
          <section>
            <AppSubHeader name="My Complaints"></AppSubHeader>
            {
              issues.map((item, index) => {
                let {issueTrackingId, issueText, createdAt, status} = item;
                return (<Box
                  sx={{
                    border: "1px solid #eae7e7",
                    background: "#fff",
                    marginBottom: 2,
                    padding: "1em",
                    maxHeight: "600px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} m={0}>
                      <Typography component="caption" className="singleline-ellipsis">{issueTrackingId}</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography component="subtitle1" className="multiline-ellipsis">{issueText}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography component="subtitle1" className="singleline-ellipsis">{format(new Date(createdAt), "dd-MMMM-yyyy")}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} sx={{ textAlign: "right" }}>
                      <Chip size="small" color="success" label={status} />
                    </Grid>
                  </Grid>
                </Box>)}
            )}
          </section>
        </Container>
        {issues.length == 0 && (<Box>{<NoRecordsFound />}</Box>)}
      </Box>
      { showSnackbar.open && <SnackbarMsg open={showSnackbar.open} message={showSnackbar.message} severity={showSnackbar.severity} onClose={() => setShowSnackbar(initialSnackbarData)}/>}
    </>
  );
};

export default MyComplaints;
