import "./deals.css";

import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MainFeaturedDeal from "./dealsBanner";
import DealsCard from "./dealsCard";
import AppSubHeader from "../Commons/appSubHeader";
import { nanoid } from "nanoid";
import { Helmet } from "react-helmet";

const DEALS_HEADER_IMG = require("./images/deals-cover.png");
const DEAL_ID = `DEAL-${nanoid()}`;

const mainFeaturedPost = {
  title: "Curated Deals and Offers",
  description:"Exciting offers from various destinations, numerous verified suppliers, and our business partners.",
  image: DEALS_HEADER_IMG,
  imageText: "promoted-deal",
};

const dealsCard = [
  {
    id: DEAL_ID,
    deal: "FamTrip",
    startDate: "12/03/2024",
    isTrending: true,
    description: `
Book 5 cabs or packages by July 10th and stand a chance to win a fantastic family trip to either Kerala or Karnataka!<br />
This is a Limited Offer! - don't miss out on this amazing opportunity! 
Stay tuned for the announcement of the trip dates!`,
    imgSrc: require("./images/fam.jpeg"),
  },
  {
    id: DEAL_ID,
    deal: "Wonderla",
    startDate: "12/03/2024",
    description: `Beat the summer heat with CabEasy x Wonderla Holidays Ltd. - Parks and Resorts<br />
Book any cab for Kerala & Karnataka and avail 15% TAC on FIT & 20% TAC on GIT Bookings ( Wonderla Ticket Price) + Complimentary Transfers to Wonderla Park.`,
    imgSrc: require("./images/wonderla.png"),
  },
  {
    id: DEAL_ID,
    deal: "hotDeal",
    startDate: "12/03/2024",
    description: "Book now and Get INR 100 discount on your next booking on CabEasy.",
    imgSrc: require("./images/hot-deal-cb.png"),
  },
];

const DealsPageContainer = () => {
  return (
    <div>
      <Helmet>
        <title>Discover deals and offers on B2B cab bookings with CabEasy! | CabEasy </title>
        <meta
          name="description"
          content="Discover exclusive deals and offers on B2B cab bookings with CabEasy! Browse curated offers from verified suppliers across top travel destinations. Save more on your next trip with the best deals in town!"
        />
        <link rel="canonical" href="https://cabeasy.in/deals" />
      </Helmet>
      <Container maxWidth="lg">
        <AppSubHeader name="Deals & Offers"></AppSubHeader>
        <MainFeaturedDeal post={mainFeaturedPost} />
        {/* Filter Area */}
        <Grid container spacing={4}>
          {dealsCard.map((val, i) => (
            <Grid item key={1} xs={12} sm={6} md={4}>
              <DealsCard destValue={val} id={val.id} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default DealsPageContainer;
