import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { collection, doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { db } from "../firebaseConfig";
import AppSubHeader from "../Commons/appSubHeader";
import LoadingButton from "../Commons/LoadingButton";
import SnackbarMsg from "../Commons/snackbarMsg";
import { useDetectMob } from "../Utility.js";

const Referals = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const [refereePhone, setRefereePhone] = useState("");
  const [userType, setUserType] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMsg, setShowMsg] = useState({ open: false, message: "" });
  const navigate = useNavigate();
  const isMobile = useDetectMob();


  const handleSelectChange = (e) => {
    let val = e.target.value;
    setUserType(val);
    // handleChange("userType", val);
  };

  const handleReferalSubmit = async () => {
    if (!refereePhone) return;
    setLoading(true);
    let docRef = doc(db, "referrals", refereePhone);
    let referralData = {
      phone: refereePhone,
      referredBy: userData.phone,
      userType,
      createdAt: +Date.now(),
    };
    let newDocRef = await setDoc(
      doc(db, "referrals", refereePhone),
      referralData,
    );
    setLoading(false);
    setShowMsg({
      open: true,
      message: "Referral Submitted! Thanks.",
      severity: "success",
    });
    navigate("/home");
  };

  // console.log("referral render ", isMobile);

  return (
    <>
      <Helmet>
        <title>Refer and Earn: Refer a travel industry friend and earn rewards in CabEasy</title>
        <link rel="canonical" href="https://cabeasy.in/referral" />
      </Helmet>

      {showMsg && (
        <SnackbarMsg
          open={showMsg.open}
          severity={showMsg.severity || "success"}
          message={showMsg.message}
          onClose={() => setShowMsg({ open: false, message: "" })}
        />
      )}
      <AppSubHeader name="Referals" />
      <img src="https://firebasestorage.googleapis.com/v0/b/persuasive-ego-377212.appspot.com/o/website%2Freferral.png?alt=media&token=582611d0-c007-4a74-ae4c-ee541d8b4352" 
        alt="Refer a friend" width="100%" style={{ marginTop: isMobile ? "-35px" : "-125px" }} 
      />
      <Grid
        container
        columnSpacing={2}
        sx={{ p: { md: 2, xs: 1 }, background: "#fff", textAlign: "center", justifyContent: "center" }}
      >
        <Grid item md={6} xs={12} sx={{ mb: { xs: 2 } }}>
          <Typography variant="caption">Business Type: &nbsp;</Typography>
          <ToggleButtonGroup
            color="primary"
            value={userType}
            exclusive
            onChange={handleSelectChange}
            aria-label="Platform"
          >
            <ToggleButton value="agent">Agent</ToggleButton>
            <ToggleButton value="supplier">Supplier</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item md={12} xs={12} sx={{ mb: { xs: 2 } }}>
          <TextField
            label="Phone No of Referre*"
            variant="outlined"
            sx={{ width: "100%", maxWidth: "300px" }}
            type="number"
            onChange={(e) => setRefereePhone(e.target.value)}
          />
        </Grid>
        
        <Grid item md={6} xs={12} sx={{ mb: { xs: 2 } }}>
          {/**(<LoadingButton
                                loading={loading}
                                type="submit"
                                sx={{ mt: 3, mb: 2, width: "20em" }}
                                onClick={handleReferalSubmit}
                              >
                                Refer
                              </LoadingButton>) **/}
          {
            loading ? (<CircularProgress sx={{ mt: 1 }} />)
            : (<Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, mb: 2, width: "10em" }}
              onClick={handleReferalSubmit}
            >
              Refer
            </Button>)
          }
        </Grid>
      </Grid>
    </>
  );
};

export default Referals;
