/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React, { useEffect, useMemo, useState } from "react";
// import { Helmet } from "react-helmet";

import { useNavigate, useLocation } from "react-router-dom";
import FullScreenAppPopup from "../Commons/fullScreenAppPopup";
import MyDailog from "../Commons/myDailog.jsx";
import Navbar from "../Navbar";
import { MainContext, devConsolelog } from "../Utility";
import KycPending from '../SignUp/kycPending';

const RedirectComponent = () => {
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // window.location = "/login";
    navigate("/login", { state: { loginRedirectUrl: location.pathname } });
  }, []);
};

function PrivateRoute({ children, authed = false, props }) {
  console.log("PrivateRoute - ", props)
  if (typeof window !== "undefined") {
    if (authed) return <>{children}</>;
    else return <RedirectComponent />;
  } else {
    return null;
  }
}

// check for the last day when kyc pop up was shown, show only once a day. 
const lastKycShowTime = localStorage.getItem("kycPopUpTime");
const checkShowKycPop = ( new Date(Date.now()).getDate() - new Date(Number(lastKycShowTime || Date.now())).getDate() ) >= 1 ;
if(lastKycShowTime === null) localStorage.setItem("kycPopUpTime", Date.now());
console.log("checkShowKycPop", checkShowKycPop, new Date(Date.now()).getDate(), new Date(Number(localStorage.getItem("kycPopUpTime") || Date.now())).getDate());

const AppLayout = ({ children, showNavBar = true }) => {
  const userData = JSON.parse(localStorage.getItem("user") || null);
  const isUserAgent = Boolean((userData || {}).isAgent);
  const isLoggedIn = Boolean(userData);

  const [showAgentKyc, setAgentKyc] = useState(checkShowKycPop);
  devConsolelog("AppLayout render ", showAgentKyc)
  // const IS_KYC_POPUP_SKIPPED =
  //   localStorage.getItem("kyc-popup-skip") === "true" || false;

  const contextValue = useMemo(
    () => ({
      userData,
      isUserAgent,
      isLoggedIn,
    }),
    [userData, isUserAgent, isLoggedIn]
  );

  const navigate = useNavigate();

  const handleClose = () => {
    devConsolelog("close kyc popup", showAgentKyc, checkShowKycPop);
    setAgentKyc(false);
  };

  useEffect(() => {
    if(!Boolean(userData)) return;
    console.log('show kyc popup 1', showAgentKyc, checkShowKycPop, isUserAgent, !userData?.isKycVerified);
    if (!isUserAgent && !userData.isKycVerified)
      navigate("/kyc-pending");
    else if (isUserAgent && !userData.isKycVerified && showAgentKyc ) {
      setAgentKyc(showAgentKyc);
      // if(checkShowKycPop) {
        try {
          localStorage.setItem("kycPopUpTime", Date.now());
        } catch (e) {
          console.log("localStorage kycPopUpTime error, e")
        }
      // }
    }

  }, [userData, isUserAgent]);

  return (<>
    {/** (<FullScreenAppPopup handleClose={handleClose} open={showAgentKyc} />) **/}

    <PrivateRoute authed={isLoggedIn}>
      <MainContext.Provider value={contextValue}>
        <div id="myDiv" className="animate-bottom">
          {showNavBar && <Navbar />}
          <Container maxWidth="md" fixed>
            <Box component="main" sx={{ pt: 9, pb: 2 }}>
              {children}
            </Box>
          </Container>
        </div>
      </MainContext.Provider>
    </PrivateRoute>
    {showAgentKyc && <MyDailog showDailog={showAgentKyc} body={<KycPending />} handleClose={handleClose} />}
  </>);
};

export default AppLayout;
