import { Helmet } from 'react-helmet';

const CancellationPolicy = () => {

    return (<div>
        <Helmet>
            <title>Cancellation Policy - CabEasy</title>
        </Helmet>
        <div style={{"backgroundColor": "#fff", "borderRadius": "8px", "boxShadow": "0px 0px 10px rgba(0, 0, 0, 0.1)", "maxWidth": "800px", "margin": "20px auto", "padding": "20px"}}>
            <h1 style={{"color": "#FFB400", "textAlign": "center", "fontSize": "36px"}}>Cancellation Policy</h1>
            
            <h2 style={{"color": "#000000", "fontSize": "28px", "borderBottom": "2px solid #FFB400", "paddingBottom": "10px"}}>Our Policy</h2>
            <p style={{fontSize: "16px", lineHeight: "1.6"}}>
                At CabEasy, we value your time and business. We aim to provide you with excellent services, but we also understand that changes in travel plans are sometimes unavoidable. Below is our cancellation policy:
            </p>

            <ul style={{"listStyleType": "disc", "marginLeft": "20px", "fontSize": "16px"}}>
                <li style={{"margin": "10px 0"}}>
                    Cancellations of any booking made a minimum of 3 days prior to the travel date are eligible for a full refund of the token booking amount. However, cancellation requests made after this timeline will not be applicable for a refund.
                </li>

                <li style={{"margin": "10px 0"}}>
                    Cancellations made on or after the travel date will not be eligible for any refund of the booking amount or any partial amount paid to CabEasy or directly to the supplier.
                </li>

                <li style={{"margin": "10px 0"}}>
                    If a cancellation is initiated by the supplier on or after 3 days prior to the travel date, the agent will be entitled to a full refund of the amount paid to CabEasy.
                </li>

                <li style={{"margin": "10px 0"}}>
                    Any legal matter with CabEasy should be filed in the New Delhi jurisdiction.
                </li>
            </ul>

            <p style={{"fontSize": "16px", "lineHeight": "1.6"}}>
                For more information on our cancellation policy or if you have any questions, feel free to reach out to our customer support team.
            </p>
        </div>
    </div>)
}

export default CancellationPolicy;