import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const PostReqPopUp = (props) => {
  console.log("PostReqPopUp ", props)

  return (<Box sx={{
        bgcolor: 'background.paper',
        // border: '1px solid #000',
        // boxShadow: 24,
        textAlign: 'center',
      }}>
        <Typography sx={{ textAlign: 'center', color: 'orange', fontSize: '20px' }}>
        Requirement Posted<span style={{ color: 'orange',fontSize: '25px' }}> ✔</span>
        </Typography>
        <Typography sx={{ mt: 2, textAlign: 'left', fontSize:"15px"}}>
          <ul>
            <li> We are notifying all suppliers matching your Requirement.</li>
            <li>You will recieve emails for new quotations/bids on your email and here on the platform.</li>
            <li> You can Book or Decline a bid.</li>
          </ul>
        </Typography>
        <Button
          size="small"
          variant="contained"
          href={`/req/view-bids/${props.redirectLink}`}
          sx={{ alignItems: 'center', mt: 2 }}
        >
          Go to Req Bids
        </Button>
    </Box>);
};

export default PostReqPopUp;
