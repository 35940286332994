import React, {useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const PopUp = (props) => {
  const [disableButton, setDisableButton] = useState(false);

  const handleOnClick = () => {
    setDisableButton(true);
    props.onClick(); 
  }

  return (
    <Modal open={props.open}
      onClose={props.onClose}
      disableEnforceFocus
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 3,
        textAlign: 'center',
      }}>
        <Typography sx={{ textAlign: 'center', color: 'orange', fontSize: '18px' }}>
          {props.primaryText}<span style={{ color: 'orange',fontSize: '25px' }}>{props.checkMark && " ✔"} </span>
        </Typography>
        <Typography sx={{ textAlign: 'center', color: 'orange', fontSize: '16px' }}>
          {props.secondaryText}
        </Typography>
        <Typography sx={{ mt: 2, textAlign: 'left', fontSize:"14px"}}>
          <ul>
            <li>{props.secondaryText1}</li>
            <li>{props.secondaryText2}</li>
            <li>{props.secondaryText3}</li>
          </ul>
        </Typography>
        <Button
          size="small"
          variant="contained"
          href={props.forwardRef || null} 
          onClick={props.onClick}
          sx={{ alignItems: 'center', mt: 2 }}
        >
          {props.submitText}
        </Button>
      </Box>
    </Modal>
  );
};

export default PopUp;
