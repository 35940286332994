import { useState } from "react";
import StatsTable from "./StatsTable";
import AppSubHeader from "../Commons/appSubHeader";
import { StatsContainer } from "./StatsStyles";

const DashStatistics = ({ isAgent }) => {
  return (
    <StatsContainer>
      <AppSubHeader name={"Your Statistics: "} />
      <StatsTable isAgent={isAgent} />
    </StatsContainer>
  );
};

export default DashStatistics;
