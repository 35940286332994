import "./postReq.css";

import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1602216056096-3b40cc0c9944",
    title: "Kerala",
    value: "Kerala",
  },
  {
    img: "https://images.unsplash.com/flagged/photo-1559717865-a99cac1c95d8",
    title: "Dubai",
    value: "Dubai",
  },
  {
    img: "https://images.unsplash.com/photo-1631714712922-eaa39e4452fa",
    title: "Karnataka",
    value: "Karnataka",
  },
  {
    img: "https://images.unsplash.com/photo-1616190419596-e2839e7380d7",
    title: "Kashmir",
    value: "Kashmir",
  },
  {
    img: "https://images.unsplash.com/photo-1651317720959-2ee65b345736?q=80&w=500&auto=format&fit=crop",
    title: "Himachal",
    value: "Himachal",
  },
  {
    img: "https://images.unsplash.com/photo-1586618761884-a062d527523f?q=80&w=500&auto=format&fit=crop",
    title: "Uttrakhand",
    value: "Uttrakhand",
  },
  {
    img: "https://plus.unsplash.com/premium_photo-1661962556769-5beec5563911?q=80&w=500&auto=format&fit=crop",
    title: "Rajasthan",
    value: "Rajasthan",
  },
  {
    img: "https://plus.unsplash.com/premium_photo-1697730400710-9e565f54869e?q=80&w=500&auto=format&fit=crop",
    title: "Sikkim-Darjeeling",
    value: "Sikkim_Darjeeling"
  },
  {
    img: "https://images.unsplash.com/photo-1567241803528-0e183644074f?q=80&w=500&auto=format&fit=crop",
    title: "Bhutan",
    value: "Bhutan"
  }
  // ,
  // {
  //   img: "https://images.unsplash.com/photo-1524661135-423995f22d0b?q=80&w=500&auto=format&fit=crop",
  //   title: "Any Other Destination",
  //   value: "Others"
  // },
];

const DestImgList = ({ handleDestSelect }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (<>
    <Box sx={{mt: 1, mb: 1, textAlign:'center'}}>
      <Typography variant="body1" gutterBottom>
        Or Select from our Top Destinations
      </Typography>
    </Box>
    <ImageList
      gap={8}
      sx={
        !isMobile
          ? {
              margin: "0px auto",
              maxWidth: "60vw",
              textAlign: "center",
              gridTemplateColumns: "repeat(4, 2fr) !important",
            }
          : { widht: "100%" }
      }
    >
      {itemData.map((item) => (
        <ImageListItem
          key={item.img}
          className="destinationCard"
          onClick={() => { 
            // if (item.value === "Others") {
            //   //redirect to generic form
            //   handleDestSelect();
            //   return;
            // }
            handleDestSelect({ target: { value: item.value } }, "destination");
          }}
          style={!isMobile ? { alignItems: "center", fontWeight: 500 } : {}}
        >
          <img
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=248&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
            style={{
              height: "18vh",
              // maxWidth: !isMobile && "20vw",
              borderRadius: "8px 8px 4px 4px",
            }}
          />
          <ImageListItemBar title={item.title} position="below" />
        </ImageListItem>
      ))}
    </ImageList>
  </>);
};

export default DestImgList;
