import React from 'react';

const ContactUs = () => {
  const containerStyle = {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    margin: '20px auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif'
  };

  const titleStyle = {
    color: '#FFB400',
    textAlign: 'center',
    fontSize: '36px'
  };

  const paragraphStyle = {
    fontSize: '16px',
    lineHeight: '1.6',
    color: '#333'
  };

  const infoStyle = {
    fontSize: '16px',
    lineHeight: '1.6',
    color: '#333',
    margin: '10px 0'
  };

  const listStyle = {
    listStyleType: 'none',
    padding: '0',
    fontSize: '16px',
    lineHeight: '1.6'
  };

  const listItemStyle = {
    margin: '10px 0'
  };

  const linkStyle = {
    color: '#FFB400',
    textDecoration: 'none',
    fontWeight: 'bold'
  };

  const inputStyle = {
    padding: '10px',
    fontSize: '16px',
    marginBottom: '10px',
    width: '100%',
    borderRadius: '5px',
    border: '1px solid #ccc'
  };

  const buttonStyle = {
    backgroundColor: '#FFB400',
    color: '#fff',
    padding: '10px 20px',
    fontSize: '16px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
    marginTop: '10px'
  };

  return (
    <div style={{ backgroundColor: '#f7f7f7', padding: '20px' }}>
      <div style={containerStyle}>
        <h1 style={titleStyle}>Contact Us</h1>
        <p style={paragraphStyle}>
          We’re here to help you! If you have any questions, concerns, or need support, feel free to get in touch with us.
        </p>

        <h2 style={titleStyle}>Reach Us At:</h2>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            📞 <strong>Phone:</strong> <a href="tel:+919122650133" style={linkStyle}>+91 91226 50133</a>
          </li>
          <li style={listItemStyle}>
            📧 <strong>Email:</strong> <a href="mailto:system@cabeasy.in" style={linkStyle}>system@cabeasy.in</a>
          </li>
          <li style={listItemStyle}>
            🌐 <strong>Website:</strong> <a href="https://cabeasy.in" style={linkStyle}>https://cabeasy.in</a>
          </li>
        </ul>

        {/* <h2 style={titleStyle}>Send Us a Message</h2>
        <form>
          <input type="text" placeholder="Your Name" style={inputStyle} required />
          <input type="email" placeholder="Your Email" style={inputStyle} required />
          <input type="text" placeholder="Subject" style={inputStyle} required />
          <textarea placeholder="Your Message" style={{...inputStyle, height: '120px'}} required />
          <button type="submit" style={buttonStyle}>Send Message</button>
        </form> */}

        <h2 style={titleStyle}>Office Address</h2>
        <p style={infoStyle}>
          CabEasy<br />
          285 UG Floor, OM Vihar, Phase 1A, Uttam Nagar<br />
          New Delhi, India - 110059
        </p>

        <h2 style={titleStyle}>Follow Us</h2>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            👍 <a href="https://www.facebook.com/profile.php?id=61564441690872" style={linkStyle}><strong>Facebook:</strong></a>
          </li>
          <li style={listItemStyle}>
            🐦 <a href="https://www.linkedin.com/company/cabeasy-technologies/" style={linkStyle}><strong>linkedin:</strong></a>
          </li>
          <li style={listItemStyle}>
            📷 <a href="https://www.instagram.com/cabeasy.in" style={linkStyle}><strong>Instagram:</strong></a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactUs;
