import React from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";

export default function DealsCard({ destValue }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  console.log(destValue);
  return (
    <Card sx={{ maxWidth: 345, position: "relative" }}>
      {/* <CardHeader subheader="Deal expired on"></CardHeader> */}
      <CardMedia
        component="img"
        height="250"
        image={destValue.imgSrc}
        alt="deals-card-cover-img"
      />
      {/* <CardContent>
        {destValue?.isTrending && <Chip variant="filled" label="Trending"></Chip>}          
      </CardContent> */}
      <CardActions disableSpacing>
        <Button variant="primary" onClick={handleExpandClick} sx={{ right: 0, margin:'0px auto', textDecoration:'underline' }}>
          More Details
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>{destValue.description}</Typography>
          <Typography fontWeight={'bold'}>
            For more details and bookings call on: +91-9122650133 - Khaliluz Khan
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
