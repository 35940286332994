import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from "react-router-dom";

import { PHONE_FILTER_REGEX, BID_MSGS_MAX_LENGTH, BID_DECLINE_REASONS_TYPES } from '../Constants.js';
import { devConsolelog, getUserDetails, isEmptyObject } from '../Utility.js';
import PopUp from "../Commons/messagePopUp.js";
import { Star, StarBorder, StarHalf } from "@mui/icons-material";
import Popover from '@mui/material/Popover';
import CreditCardIcon from '@mui/icons-material/CreditCard';

export default function BidRequestCard({ handleBidAccept, handleNegotiation, handleBidDecline, handleUpdateBidView, bid, price, bestBid = false }) {
  const [showBidDeclineMsg, setShowBidDeclineMsg] = useState(false);
  const [bidDeclineMsg, setBidDeclineMsg] = useState({});
  // const [showCustomDeclineMsg, setShowCustomDeclineMsg] = useState(false);
  const [declineMsgError, setDeclineMsgError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmBook, setShowConfirmBook] = useState(false);
  const navigate = useNavigate();
  const openMenu = Boolean(anchorEl);
  const userData = JSON.parse(localStorage.getItem("user"));
  const isUserAgent = !(userData.isSupplier);
  const [mouseOver, setMouseOver] = useState(null);
  const [allRating, setAllRating] = useState({});
  const [overallRating, setOverallRating] = useState(0);
  const [supplierRating, setSupplierRating] = useState({
    overallRating: 0,
    ratings: {}
  });


  const getuserRating = useCallback(async () => {
    const userDetailsData = await getUserDetails(bid.supplierId);
    console.log("USERID : ", userDetailsData);
    if (userDetailsData) {
      setSupplierRating({
        overallRating: userDetailsData?.ratings?.overallRating,
        ratings: userDetailsData?.ratings
      })
    }
  }, [bid.supplierId]);

  useEffect(() => {
    getuserRating();
  }, []);

  // console.log("bidRequest Rating : ", overallRating);
  // console.log("bidRequest phone : ", userData);
  // console.log("bidRequest allrating : ", allRating);

  const handlePopoverOpen = (event) => {
    setMouseOver(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setMouseOver(null);
  };
  const openMouseOver = Boolean(mouseOver);

  const supplierActions = [
    { name: 'Update Bid', type: 'update_bid', clickHandler: () => handleUpdateBidView(true) }
  ];
  const agentActions = [];
  const actions = isUserAgent ? agentActions : supplierActions;

  const bidDeclineClick = () => {
    setShowBidDeclineMsg(!showBidDeclineMsg);
    // handleBidDecline(bid);
  }

  const onDeclineMsgChange = (e, val) => {
    if (val && val?.label.length > BID_MSGS_MAX_LENGTH) {
      setDeclineMsgError("Max 100 characters allowed here");
      // setTimeout(() => setDeclineMsgError(""), 3000);
      return;
    } else if (declineMsgError) setDeclineMsgError("");
    val.label = val.label.replace(PHONE_FILTER_REGEX, ' ');
    setBidDeclineMsg(val);
    // console.log("onDeclineMsgChange ", e, val, val.value == "none_of_the_above");
  }

  const handleSubmit = (finalData = null) => {
    // setShowCustomDeclineMsg(false);
    handleBidDecline(finalData);
  }

  const handleActionSelect = async (action = '') => {
    let { name = "", type = "", clickHandler } = action;
    console.log('handleActionSelect  update ', type, clickHandler);
    setAnchorEl(null)

    if (!clickHandler) { }
    else {
      await clickHandler();
    }
  }

  const handleConfirmBooking = () => {
    handleBidAccept(bid);
    setShowConfirmBook(false);
  }

  const ITEM_HEIGHT = 48;

  const RatingStars = ({ rating }) => {
    const stars = [];
    const wholeStars = Math.floor(rating);
    const halfStar = rating - wholeStars > 0;

    for (let i = 0; i < 5; i++) {
      if (i < wholeStars) {
        stars.push(<Star key={i} sx={{ fontSize: 20, color: 'orange' }} />);
      } else if (i === wholeStars && halfStar) {
        stars.push(<StarHalf key={i} sx={{ fontSize: 20, color: 'orange' }} />);
      }
      //  else {
      //   stars.push(<StarBorder key={i} sx={{ fontSize: 16, color: 'orange'}} />);
      // }
    }
    return <span>{stars}</span>;
  };

  const getFilteredRatings = () => {
    if(isEmptyObject(supplierRating?.ratings)) return [];
    let filteredRatingKeys = Object.keys(supplierRating?.ratings).filter((ele) => ele !== 'otherFeedback' && ele !== 'experience' && ele !== 'countRating' && ele !== 'overallRating');
    console.log("filte rating", filteredRatingKeys, supplierRating?.ratings);
    return filteredRatingKeys.map((key) => {
      return {key: key.charAt(0).toUpperCase() + key.slice(1), rating: supplierRating?.ratings[key] }
    });
  };

  const RatinsPopUp = () => {
    let filteredRatings = useMemo(() => getFilteredRatings(supplierRating?.ratings), [supplierRating.ratings]);

    return (<Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={openMouseOver}
      anchorEl={mouseOver}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      {/* experience */}
      <Typography sx={{ p: 1 }}>
        { 
          filteredRatings.map(({key, rating}) => (
          <Typography key={key}>
            {key.charAt(0).toUpperCase() + key.slice(1)}   :
            <RatingStars rating={rating} />
          </Typography>
          ))
        }
      </Typography>
    </Popover>)
  }

  devConsolelog("bid card render ", bidDeclineMsg)
  return (<>
    <Card>
      <Box sx={{ p: 2 }}>
        <Grid container>
          <Grid item>
            <Box display="flex" flexDirection="column">

              <Box display="flex">
               {/* <Typography sx={{fontSize:"9px", marginY:"auto"}}>Id: {bid?.id}</Typography> */}
                <Chip
                  sx={{ fontSize:"9px", padding:0}}
                  variant="outlined"
                  size="small"
                  className="singleline-ellipsis"
                  label={`ID: ${bid?.id}`}
                />
                {(isUserAgent && bestBid) && (<Chip
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  size="small"
                  color="primary"
                  className="singleline-ellipsis"
                  label={<span>Best Price</span>}
                />)}
              </Box>
              <Typography variant="h5" color="text.secondary">
                <b>₹{price}</b>
              </Typography>
              {bid?.message && (<Typography variant="caption" color="text.secondary">
                <b>{bid?.message}</b>
              </Typography>)}
              {bid?.extraPackagePrice && (<Typography variant="body2" color="text.secondary">
                Package Price:&nbsp;<b>{bid?.extraPackagePrice}</b>
              </Typography>)}
              {supplierRating?.overallRating && (
                <Typography
                  aria-owns={openMouseOver ? 'mouse-over-popover' : undefined}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  variant="caption"
                  color="text.secondary"
                  alignItems="center"
                  display="inline"
                  font="20px"
                >
                  <RatingStars rating={supplierRating?.overallRating} />
                </Typography>

              )}
              <RatinsPopUp />
              { /** (<Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={openMouseOver}
                anchorEl={mouseOver}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography sx={{ p: 1 }}>
                  {supplierRating?.ratings && Object.entries(supplierRating?.ratings).filter(([ele]) => ele !== 'otherFeedback' && ele !== 'experience' && ele !== 'countRating' && ele !== 'overallRating').map(([key, rating]) => (
                    <Typography key={key}>
                      {key.charAt(0).toUpperCase() + key.slice(1)}   :
                      <RatingStars rating={rating} />
                    </Typography>
                  ))}
                </Typography>
              </Popover>) **/}
            </Box>
          </Grid>
          <Grid item sx={{ flexGrow: 1, sm: { flexGrow: 1, mx: 1 } }}>
            {userData.isSupplier && bid?.seenStatus && (<Tooltip title="Bid is seen by Agent">
              <MarkChatReadIcon color="primary" fontSize="small" sx={{ my: 'auto', mx: 1 }} />
            </Tooltip>)}
          </Grid>
          <Grid>

            {/**
              isUserAgent && (
                <CardActions>
                <Grid item sx={{ marginY:2, mx:1 }}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => setShowConfirmBook(true)}
                    color="primary"
                  >
                    Book
                  </Button>
                </Grid>
                <Grid item sx={{ mx: 1 }}>
                  <IconButton size="small" sx={{ color: "text.secondary" }} onClick={bidDeclineClick} >
                    {
                      showBidDeclineMsg
                        ? (<ThumbDownIcon fontSize="small" color="secondary" />)
                        : (<ThumbDownAltOutlinedIcon fontSize="small" color="secondary" />)
                    }
                  </IconButton>
                </Grid>
              </CardActions>)
            **/}
            {
              !isUserAgent && bid.status == "declined" && (<Grid item sx={{ flexGrow: 1, sm: { flexGrow: 1, mx: 1 } }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle1" color="error">
                    Declined
                  </Typography>
                  {bid.bidDeclineMsg?.label && (<Typography variant="caption">
                    {`( ${bid.bidDeclineMsg?.label || bid?.bidDeclineMsg} )`}
                  </Typography>)}
                </Box>
              </Grid>)
            }
          </Grid>
          {
            isUserAgent && (<CardActions>
              {bid.isNegotiable? (<Grid item sx={{ mx: 1 }}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleNegotiation(bid)}
                  color="success"
                  disabled={ bid.agentNegotiateAttempts >=1 }
                >
                  Negotiate
                </Button>
              </Grid>) : null}
              <Grid item sx={{ mx: 1 }}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => handleBidAccept(bid)}
                  color="primary"
                >
                  Book
                </Button>
              </Grid>
              <Grid item sx={{ mx: 1 }}>
                <IconButton size="small" sx={{color:"text.secondary"}} onClick={bidDeclineClick} >
                {
                  showBidDeclineMsg 
                  ? (<ThumbDownIcon fontSize="small" color="secondary" />)
                  : (<ThumbDownAltOutlinedIcon fontSize="small" color="secondary" />)
                }
              </IconButton>
              </Grid>
            </CardActions>)
          }
          {
            !isUserAgent && bid.status == "declined" && (<Grid item sx={{flexGrow:1, sm:{flexGrow:1, mx:1} }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" color="error">
                  Declined 
                </Typography>
                {bid.bidDeclineMsg && (<Typography variant="caption">
                  {`( ${bid.bidDeclineMsg?.label || bid.bidDeclineMsg} )`}
                </Typography>)}
              </Box>
            </Grid>) 
          }
          {
            !isUserAgent && (<Grid item sx={{ m: 0, mt: 1, display: "flex", flexDirection: "row", flexGap: 1 }}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openMenu ? 'long-menu' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{ padding: '0px!important' }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                sx={{ padding: '0px!important' }}
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    // width: '20ch',
                  },
                }}
              >
                {actions.map((action) => (
                  <MenuItem key={action.type} onClick={() => handleActionSelect(action)}>
                    {action.name}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>)}
        </Grid>
        {
          showBidDeclineMsg && (<CardContent sx={{ m: 0, p: 0, pb: 0 }} style={{ width: '100% !important', margin: 0, padding: 0, }}>
            <hr style={{ width: '100%' }} />
            {declineMsgError && (<Typography variant="caption" color="error">{declineMsgError}</Typography>)}
            <Grid item sx={{ m: 0, display: "flex", flexDirection: "row", flexGap: 1 }}>
              <FormControl sx={{ m: "8px 0px", flexGrow: 1, width: '30%' }}>
                {/** <InputLabel htmlFor="bidDeclineMsg" shrink={true} size="small" variant="outlined">
                                        Reason (Optional)
                                      </InputLabel>
                                      <OutlinedInput
                                        id="bidDeclineMsg"
                                        onChange={onDeclineMsgChange}
                                        type="text"
                                        value={bidDeclineMsg}
                                      /> **/}
                <Autocomplete
                  id="bid-decline-feedback"
                  onChange={(e, val) => onDeclineMsgChange(e, val)}
                  renderInput={(params) => (
                    <TextField {...params} label="Feedback" variant="outlined" />
                  )}
                  options={BID_DECLINE_REASONS_TYPES}
                  defaultValue={bidDeclineMsg.label}
                  sx={{ width: '100%', zIndex: 1 }}
                />
              </FormControl>

              &nbsp;&nbsp;
              <Button size="small" variant="contained" color="secondary" sx={{ margin: 'auto' }} onClick={() => handleSubmit({ ...bid, bidDeclineMsg })} >Decline</Button>
            </Grid>
            <Grid item sx={{ m: 0, mt: 1, display: "flex", flexDirection: "row", flexGap: 1 }}>
              <FormControl sx={{ width: '100%' }}>
                {
                  (bidDeclineMsg.value == "none_of_the_above") && (<>
                    <InputLabel htmlFor="bidDeclineMsg" shrink={true} size="small" variant="outlined">
                      Other Reason? (Optional)
                    </InputLabel>
                    <OutlinedInput
                      id="bidDeclineMsg"
                      onChange={(e) => onDeclineMsgChange(e, { label: e.target.value, value: "none_of_the_above" })}
                      type="text"
                      value={bidDeclineMsg.label}
                      label="Any Other Resason?"
                      sx={{ width: '100%' }}
                    />
                  </>)
                }

              </FormControl>
            </Grid>
          </CardContent>)
        }
      </Box>
    </Card>
    <PopUp
      open={showConfirmBook}
      onClose={() => setShowConfirmBook(false)}
      checkMark={true}
      primaryText="Confirm booking with this supplier"
      secondaryText1="If you like this bid and want to confirm a booking, click Confirm below."
      secondaryText2="Once booking is created, you will be sent a mail and you can track your booking in My Bookings page."
      secondaryText3="You can directly chat with the supplier for the booking. CabEay will help you if any issues or confusion in the booking."
      onClick={handleConfirmBooking}
      submitText="Confirm Booking"
    />
  </>);
}
