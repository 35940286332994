import React from 'react';

const ServiceDeliveryPolicy = () => {
  const containerStyle = {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    margin: '20px auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif'
  };

  const titleStyle = {
    color: '#FFB400',
    textAlign: 'center',
    fontSize: '36px'
  };

  const headingStyle = {
    color: '#000',
    fontSize: '28px',
    borderBottom: '2px solid #FFB400',
    paddingBottom: '10px'
  };

  const paragraphStyle = {
    fontSize: '16px',
    lineHeight: '1.6'
  };

  const listStyle = {
    listStyleType: 'disc',
    marginLeft: '20px',
    fontSize: '16px'
  };

  const listItemStyle = {
    margin: '10px 0'
  };

  return (
    <div style={{ backgroundColor: '#f7f7f7', padding: '20px' }}>
      <div style={containerStyle}>
        <h1 style={titleStyle}>Service Delivery Policy</h1>
        
        <h2 style={headingStyle}>Introduction</h2>
        <p style={paragraphStyle}>
          CabEasy is a technology platform that connects two parties—service providers (cab operators) and agents (travel agents or end customers)—to facilitate smooth and efficient cab booking processes. This policy outlines how CabEasy manages communication, booking, and service delivery between both parties to ensure a hassle-free experience.
        </p>

        <h2 style={headingStyle}>Platform Responsibilities</h2>
        <p style={paragraphStyle}>As a tech platform, CabEasy ensures:</p>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Communication Management:</strong> All communication between the agent and the supplier is managed through CabEasy’s platform, ensuring transparency and timeliness. Real-time updates are provided to both parties regarding booking status, payment, and other important details.
          </li>
          <li style={listItemStyle}>
            <strong>Booking Confirmation:</strong> Once a booking request is made, CabEasy facilitates the confirmation process by sending notifications to the selected supplier. Upon confirmation, CabEasy updates the agent with the details of the confirmed booking.
          </li>
          <li style={listItemStyle}>
            <strong>Payment Facilitation:</strong> CabEasy manages the payment gateway between the agent and supplier, ensuring secure transactions. Payment-related queries are handled by CabEasy, and the platform ensures that payments are processed promptly.
          </li>
          <li style={listItemStyle}>
            <strong>Dispute Resolution Support:</strong> In case of any dispute or issue between the agent and supplier, CabEasy offers support by facilitating communication between the parties and assisting in reaching a resolution.
          </li>
        </ul>

        <h2 style={headingStyle}>Agent Responsibilities</h2>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Accurate Information:</strong> The agent must provide accurate and up-to-date information when making a booking request, including travel dates, destination, and contact details.
          </li>
          <li style={listItemStyle}>
            <strong>Timely Communication:</strong> Agents are expected to respond promptly to any communication from CabEasy or the service provider regarding booking confirmation, cancellations, or any other matters.
          </li>
          <li style={listItemStyle}>
            <strong>Payment:</strong> Agents must complete the required payments on time through CabEasy’s payment gateway to confirm the booking.
          </li>
        </ul>

        <h2 style={headingStyle}>Supplier Responsibilities</h2>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Service Fulfillment:</strong> Suppliers must ensure that the cab service is provided as per the confirmed booking details, including punctuality and quality of service.
          </li>
          <li style={listItemStyle}>
            <strong>Timely Updates:</strong> Suppliers are expected to update CabEasy and the agent in case of any changes, delays, or cancellations.
          </li>
          <li style={listItemStyle}>
            <strong>Refund Policy Compliance:</strong> Suppliers must adhere to CabEasy’s refund policy, ensuring that agents receive refunds in case of cancellations or service issues.
          </li>
        </ul>

        <h2 style={headingStyle}>Service Delivery Process</h2>
        <p style={paragraphStyle}>
          CabEasy ensures that the booking process between the agent and supplier is smooth and straightforward. Once the agent posts a request, CabEasy shares the request with relevant suppliers. Upon receiving quotes, the agent can choose the supplier, after which CabEasy manages the confirmation and payment process.
        </p>
        <p style={paragraphStyle}>
          After confirmation, the supplier is responsible for providing the service as per the agreed-upon terms. The agent will receive real-time updates on the booking status through CabEasy’s platform.
        </p>

        <h2 style={headingStyle}>Dispute Resolution</h2>
        <p style={paragraphStyle}>
          In the event of any disputes related to booking, service delivery, or payments, CabEasy will act as a mediator between the agent and the supplier. CabEasy will investigate the issue and aim to resolve it fairly. Both parties are encouraged to use the platform for all communication during the dispute resolution process.
        </p>

        <p style={paragraphStyle}>
          For further information or queries about our service delivery process, feel free to reach out to our support team at any time.
        </p>
      </div>
    </div>
  );
};

export default ServiceDeliveryPolicy;
