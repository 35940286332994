import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";

const AppSubHeader = ({ name }) => {
  return (
    <Box sx={{mt: 1, mb: 1, textAlign:'center'}}>
      <Typography variant="subtitle1" gutterBottom>
        <b>{name}</b>
      </Typography>
    </Box>
  );
};

export default AppSubHeader;
