import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { format } from "date-fns";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import AppSubHeader from "../Commons/appSubHeader";
import { db } from "../firebaseConfig";
import NoRecordsFound from "../layout/NoRecordsFound";
import { copyTextToClipboard, getStatusColor, MainContext } from "../Utility";

const Accounts = () => {
  const [allBookings, setAllBookings] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const { userData, isUserAgent } = useContext(MainContext);
  useEffect(() => {
    const getAccounts = async () => {
      const supplierQuery = query(
        collection(db, "bookings"),
        where("supplierId", "==", userData.phone)
      );
      const agentQuery = query(
        collection(db, "bookings"),
        where("req.agentId", "==", userData.phone)
      );
      const querySnapshot = await getDocs(
        isUserAgent ? agentQuery : supplierQuery
      );
      let data = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, "Accounts => ", doc.data());
        data.push(doc.data());
      });
      setAllBookings(data);
    };

    getAccounts();
  }, []);

  const handleCopyId = (elemId = null) => {
    if (!elemId) return;
    copyTextToClipboard(elemId);
    setOpenSnack(true);
  };

  return (
    <>
      <Helmet>
        <title>
          CabEasy Easy Accounts: All your acounting needs in one place
        </title>
        <link rel="canonical" href="https://cabeasy.in/accounts" />
      </Helmet>

      <AppSubHeader name="Accounts"></AppSubHeader>

      <Box sx={{ marginBottom: 10 }}>
        {allBookings.map((bookItem, i) => {
          let {
            bookingId: id = "",
            req = {},
            acceptedBid = "",
            status = "",
          } = bookItem;
          console.log("accounts item ", id, bookItem);

          let {
            trackingId = "",
            destination = "",
            startDate = "",
            route = "",
            pax,
            noOfNights = "",
          } = req || {};

          let accCardId = `accCardId-${id}`,
            trackId = `accTrackId-${trackingId}`;

          return (
            <Box
              sx={{
                border: "1px solid #eae7e7",
                background: "#fff",
                marginBottom: 2,
                padding: "1em",
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={6} md={6}>
                  <Chip
                    size="small"
                    label={<span id={accCardId}>{id || "-"}</span>}
                    icon={
                      <ContentCopyIcon
                        onClick={() => handleCopyId(accCardId)}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6} sx={{ textAlign: "right" }}>
                  {trackingId && (
                    <Chip
                      label={<span id={trackId}>{trackingId || "-"}</span>}
                      size="small"
                      icon={
                        <ContentCopyIcon
                          onClick={() => handleCopyId(trackId)}
                        />
                      }
                    />
                  )}
                </Grid>

                <Grid item xs={8} md={8} className="singleline-ellipsis">
                  {destination}
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={4}
                  sx={{ color: "gray", textAlign: "right" }}
                >
                  {format(new Date(startDate), "dd-MMMM-yyyy")}
                </Grid>
                <Grid item xs={8} md={8} className="singleline-ellipsis">
                  {route}
                </Grid>
                <Grid item xs={4} md={4} sx={{ textAlign: "right" }}>
                  ₹ <b>{acceptedBid}</b>
                </Grid>
                <Grid item xs={8} md={4} className="singleline-ellipsis">
                  {`${req.pax || "..."} Pax - ${noOfNights || "..."} Nights`}
                </Grid>
                <Grid item xs={4} md={4} sx={{ textAlign: "right" }}>
                  <Chip
                    size="small"
                    color={getStatusColor(status)}
                    label={status}
                  />
                </Grid>
              </Grid>
            </Box>
          );
        })}

        <Box>{!allBookings.length && <NoRecordsFound />}</Box>
      </Box>
    </>
  );
};

export default Accounts;
