import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Helmet } from "react-helmet";
import { format, max } from "date-fns";
import loadable from '@loadable/component';
import Typography from '@mui/material/Typography';

import {
  DestinationNames,
  DestNameKerala,
  DestNameDubai,
  DestNameKarnataka,
  DestNameKashmir,
  DestNameHimachal,
  DestNameUttrakhand,
  DestNameRajasthan,
  DestNameSikkimDarjeeling,
  DestNameBhutan,
  DestNameOthers,
} from "../Constants";
import AppSubHeader from "../Commons/appSubHeader";
import DestImgList from "./destImgList.js";
// import KeralaPostReq from "./Kerala/kerelaPostReq.js";
// import DubaiPostReq from "./Dubai/dubaiPostReq.js";
// import KarnatakaPostReq from "./Karnataka/karnatakaPostReq.js";
// import KashmirPostReq from "./Kashmir/kashmirPostReq.js";
// import HimachalPostReq from "./Himachal/himachalPostReq.js";
// import UttrakhandPostReq from "./Uttrakhand/uttrakhandPostReq.js";
// import RajasthanPostReq from "./Rajasthan/rajasthanPostReq.js";
// import SikkimDarjPostReq from "./Sikkim_Darjeeling/sikkimDarjPostReq.js";
// import GenericPostReq from "./Others/genericPostReq.js";
// import BhutanPostReq from "./Bhutan/bhutanPostReq.js";
import { sendEmail, addUserActivity } from "../Utility.js";
import MyDailog from "../Commons/myDailog.jsx";
import PostReqPopUp from "./postReqPopUp.js";
import MainDestSearch from "./mainDestSearch.js";

const PostReqDestFormLazy = loadable(
  (props) => import(`./${props.page}`), 
  { 
    fallback: <Typography variant="caption">Loading...</Typography>,
  }
);

const destinationFormMap = {
  [DestNameKerala.toLowerCase()]: (props) => (<PostReqDestFormLazy page="Kerala/kerelaPostReq.js" {...props} />),
  [DestNameDubai.toLowerCase()]: (props) => (<PostReqDestFormLazy page="Dubai/dubaiPostReq.js" {...props} />),
  [DestNameKarnataka.toLowerCase()]: (props) => (<PostReqDestFormLazy page="Karnataka/karnatakaPostReq.js" {...props} />),
  [DestNameKashmir.toLowerCase()]: (props) => (<PostReqDestFormLazy page="Kashmir/kashmirPostReq.js" {...props} />),
  [DestNameHimachal.toLowerCase()]: (props) => (<PostReqDestFormLazy page="Himachal/himachalPostReq.js" {...props} />),
  [DestNameUttrakhand.toLowerCase()]: (props) => (<PostReqDestFormLazy page="Uttrakhand/uttrakhandPostReq.js" {...props} />),
  [DestNameRajasthan.toLowerCase()]: (props) => (<PostReqDestFormLazy page="Rajasthan/rajasthanPostReq.js" {...props} />),
  [DestNameSikkimDarjeeling.toLowerCase()]: (props) => (<PostReqDestFormLazy page="Sikkim_Darjeeling/sikkimDarjeelingPostReq.js" {...props} />),
  [DestNameBhutan.toLowerCase()]: (props) => (<PostReqDestFormLazy page="Bhutan/bhutanPostReq.js" {...props} />),
  [DestNameOthers.toLowerCase()]: (props) => (<PostReqDestFormLazy page="Others/genericPostReq.js" {...props} />),
};

const PostReqDests = [
  DestNameKerala,
  DestNameDubai,
  DestNameKarnataka,
  DestNameKashmir,
  DestNameHimachal,
  DestNameUttrakhand,
  DestNameRajasthan,
  DestNameSikkimDarjeeling,
  DestNameBhutan,
  DestNameOthers,
];

const DESTINATION_TILE_HEADING = "Post New Requirement";
const POST_REQ_HEADING = "Post New Requirement";

const PostReqIndex = () => {
  const [destination, setDestination] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [showGenericForm, setShowGenericForm] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState({
    open: false,
    data: null
  });
  const userData = JSON.parse(localStorage.getItem("user"));

  const handleDestSelect = (e) => {
    let selectedDest = e?.target?.value;
    console.log("selectedDest req ", selectedDest);
    if(selectedDest == "Others") setShowGenericForm(true);
    else if (destinationFormMap[selectedDest.toLowerCase()]) {
      if(selectedDest) setDestination(selectedDest);
      setShowGenericForm(false);
    } else {
      if(selectedDest) setDestination(selectedDest);
      setShowGenericForm(true);
    }
    setTimeout(() => setShowForm(true));
  };

  const sendReqPostedNoti = async (req) => {
    console.log("subham sendReqPostedNoti ", req, userData, `${process.env.REACT_APP_CABEASY_DOMAIN}${process.env.REACT_APP_CHECK_BIDS_URL_PATH}${req?.reqId}`);
    if(!req) return;
    try {
      await sendEmail({
        to: userData?.phone,
        // name: userData.name,
        template: {
          name: "req_created_agent",
          data: {
            name: userData.name,
            reqDetailLink: `${process.env.REACT_APP_CABEASY_DOMAIN}${process.env.REACT_APP_CHECK_BIDS_URL_PATH}${req?.reqId}`,
            ...req,
            startDateView: format(new Date(req.startDate), "dd-MMMM-yyyy") || "",
          },
        },
      });
    } catch (e) {
      console.log("send req posted email error", e);
    }
  }

  const DestPostReqForm = (params) => {
    // const SelectedDestPostReqView =
    //   destinationFormMap[(destination || "Others").toLowerCase()];
    // console.log("DestPostReqForm ",destination, SelectedDestPostReqView )
    // if(!SelectedDestPostReqView) return <PostReqDestFormLazy page="Others/genericPostReq.js" {...params} />
    // return (
    //   <SelectedDestPostReqView
    //     {...params}
    //     destination={destination}
    //   />
    // );

    return <PostReqDestFormLazy page="Others/genericPostReq.js" {...params} />
  };

  const handleuserActivity = (phone_num, post_req) => {
    console.log("subham handleuserActivity ", phone_num, post_req)
    const post_message = `You posted Req for ${destination} ${post_req.trackingId}`;
    const post_cta = {
      text: "Track Req Bids",
      link: `/req/view-bids/${post_req.reqId}`,
    };
    addUserActivity(post_message, post_cta, phone_num);
    sendReqPostedNoti(post_req);
  };

  const SEO_HEADER = destination
    ? `Post ${destination} B2B Cabs requirement on CabEasy: Get multiple bids from verified suppliers`
    : "Post any B2B Cabs requirement on CabEasy: Get multiple bids from verified suppliers";

  console.log(destination, "post req destination render");

  return (
    <>
      <Helmet>
        <title>{SEO_HEADER}</title>
        <link rel="canonical" href="https://cabeasy.in/post-req" />
      </Helmet>
      <Box display="flex" flexDirection="column" height="100%">
        <AppSubHeader
          name={
            Boolean(!destination) ? DESTINATION_TILE_HEADING : POST_REQ_HEADING
          }
        ></AppSubHeader>

        <Box>
          
            <Grid container spacing={2} sx={{ marginBottom: 1 }}>
              <Grid item xs={12} sx={{ display: "flex", margin: "auto" }}>
                
                

                    {/**(<Autocomplete
                                                              disablePortal
                                                              id="destination"
                                                              includeInputInList
                                                              onChange={(e, val) =>
                                                                handleDestSelect(
                                                                  { target: { value: val } },
                                                                  "destination"
                                                                )
                                                              }
                                                              renderInput={(params) => (
                                                                <TextField
                                                                  {...params}
                                                                  label="Destination*"
                                                                  variant="outlined"
                                                                />
                                                              )}
                                                              options={DestinationNames}
                                                              value={destination}
                                                              defaultValue={""}
                                                              freeSolo
                                                              sx={{ width: "100%" }}
                                                              error={formErrors["destination"]}
                                                            />) **/}
                  
              </Grid>
            </Grid>
          
          {
            showForm 
            ? (<>
              {destination && <MainDestSearch handleDestSelect={handleDestSelect} destination={destination} />}
              <br />
              <DestPostReqForm destination={destination} handleUserActivity={handleuserActivity} setShowSuccessPopup={setShowSuccessPopup} />
            </>)
            : (<Box display="flex" flexDirection="column">
              <MainDestSearch handleDestSelect={handleDestSelect} />
              <br />
              <DestImgList handleDestSelect={handleDestSelect} />
            </Box>)
          }
          {
            showSuccessPopup.open && <MyDailog showDailog={showSuccessPopup.open} body={<PostReqPopUp redirectLink={showSuccessPopup.data?.reqId} />} handleClose={() => setShowSuccessPopup({open: false, data: null})} />
          }
        </Box>
      </Box>
    </>
  );
};

export default PostReqIndex;
// utitlity func
// call utitlity func
//const handleuserActivity = () => {};
