import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function AppLoader() {
  return (
    <center>
    <Box sx={{ display: 'block', margin:'2em auto' }}>
      <CircularProgress />
    </Box>
    </center>
  );
}
