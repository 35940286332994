import { useState, useEffect, useContext } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import Box from "@mui/material/Box";

import { db } from "../firebaseConfig";

import AppSubHeader from "../Commons/appSubHeader";
import ActivitiesTable from "./ActivitiesTable";
// import { test_data } from "./activitiesData";
import { MainContext } from "../Utility";

const formatDate = (timestamp) => {
  const date = new Date(Number(timestamp));

  if (isNaN(date.getTime())) {
    // console.error("Invalid timestamp:", timestamp);
    return "Invalid Date";
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  const strTime = `${hours}:${minutes} ${ampm}`;

  return `${day}/${month}/${year} ${strTime}`;
};

const Activities = () => {
  const { userData } = useContext(MainContext);
  let { phone = null } = userData || {};
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    if (!phone) {
      console.warn("Phone number is not available in userData.");
      return;
    }

    const activityDocRef = doc(db, "activities", phone);

    const unsubscribe = onSnapshot(
      activityDocRef,
      (doc) => {
        if (!doc.exists()) {
          // window.alert("No matching documents.");
          return;
        }

        const activityData = doc.data();
        const notifications = activityData.notifications || [];

        const sortedNotifications = notifications.sort(
          (a, b) => b.createdAt - a.createdAt
        );

        const formattedNotifications = sortedNotifications.map(
          (notification) => ({
            ...notification,
            formattedDate: formatDate(notification.createdAt),
          })
        );

        setActivities(formattedNotifications);
      },
      (error) => {
        console.error("Error fetching activities:", error);
      }
    );

    return () => unsubscribe();
  }, [phone]);

  return (
    <Box sx={{mb: 1}}>
      <AppSubHeader name={"Your recent Activities"} />
      <ActivitiesTable data={activities} />
    </Box>
  );
};

export default Activities;
