import React from "react";
const CABEASY_LOGO_WHITE = require("./cb-white.png");
const CABEASY_LOGO_BLACK = require("./cb-black.png");

const LOGO_STYLE = {
  fontWeight: "600",
  padding: "5px 25px 5px 25px",
  background: "black",
  borderRadius: "10px",
  fontSize: "1.5rem",
  border: "3px solid #fcd33a",
};
const LOGO_STYLE_NAVBAR = {
  fontWeight: "600",
  padding: "5px 25px 5px 25px",
  background: "black",
  borderRadius: "10px",
  fontSize: "1.3rem",
}

export default function CabEasyLogo({navbar}) {
  return (
    <div style={navbar ? LOGO_STYLE_NAVBAR :LOGO_STYLE}>
      <span style={{ color: "#fcd33a" }}>Cab</span>
      <span style={{ color: "#fff" }}>Easy</span>
    </div>
  );
}
