import React, { useEffect } from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

import "./App.css";
import Routes from "./Routes.js";

function App() {
  const notifyMe = () => {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      console.log("Notification Granted!!!");
      // const notification = new Notification("Hi there!");
      // …
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      console.log("Notification Denied!!!");
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          // const notification = new Notification("Hi there!");
          // …
        }
      });
    }
    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  };

  useEffect(() => {
    notifyMe();
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffc108", //'#428bfe'
      },
      secondary: {
        main: "#000000bf",
      },
      text: {
        primary: "#212121", //'#212121',  //'#7c3647',  //'rgba(124, 54, 71, 1)',
        secondary: "#212121", //'#384E89',  //'#FBAD23',  //'#ffffff',   //'rgba(255, 255, 255, 1)',
        paragraph: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.54)",
        highlight:'#FBAD23'
      },
    },
    typography: {
      fontFamily: 'Poppins, sans-serif',
      navlink: {
        color: "#000000bf",
      },
      bold: {
        fontWeight: 600,
      },
    },
    background:{
      primary:"#fcd33a",
      secondary: "#212121",
    }
  });
  const themeWithResponsiveFont = responsiveFontSizes(theme);

  return (
    <React.Fragment>
      <ThemeProvider theme={themeWithResponsiveFont}>
        <Routes />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
