import React from "react";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy | Learn How We Protect Your Data - CabEasy</title>
        <meta
          name="description"
          content="Read CabEasy's Privacy Policy to understand how we protect your personal information. Your privacy is our priority. Visit cabeasy.in for details on our data protection practices and how we ensure your information is safe while booking b2b cabs with us."
        />
        <link rel="canonical" href="https://cabeasy.in/privacy-policy" />
      </Helmet>
      <div className="container content">
        <h1>Privacy Policy</h1>
        <p>Last updated: Oct 16, 2023</p>
        <h4>Introduction</h4>
        This privacy policy sets out how CabEasy and protects any information
        that you give CabEasy when you use this website. CabEasy is committed to
        ensuring that your privacy is protected. Should we ask you to provide
        certain information by which you can be identified when using this
        website, then you can be assured that it will only be used in accordance
        with this privacy statement. CabEasy may change this policy from time to
        time by updating this page. You should check this page from time to time
        to ensure that you are happy with any changes.
        <h4>The Information We Collect</h4>
        We may collect the following information: name, email, phone number,
        login name and password, address, government identification numbers,
        birth date and photo information about your vehicle or insurance (for
        driver / partners) Location information - CabEasy may collect your
        location information as determined through data such as GPS, IP address
        and WiFi Transaction information related to your use of our services,
        including itinerary, date and time the service was provided, amount
        charged, distance travelled, promotion code, and other related
        transaction details. Log information - When you interact with our
        services, we collect server logs, which may include information like
        device IP address, access dates and times, app features or pages viewed,
        app crashes and other system activity, type of browser, and the
        third-party site or service you were using before interacting with our
        services. Feedback / ratings provided by riders, partners and any third
        party other information relevant to customer surveys and/or offers
        <h4>How we use your Information</h4>
        CabEasy uses this information to enable reliable and convenient
        transportation. We also use the information we collect: To enhance the
        safety and security of our users and services For customer support For
        research and development to improve our products and services. To enable
        communications to or between users To provide promotions or contests In
        connection with legal proceedings. &nbsp;
        <b>
          CabEasy does not sell or share your personal information to third
          parties for third party direct marketing purposes.
        </b>
        <h4>Cookies And Third Party Technologies</h4>
        Cookies are small text files that are stored on your browser or device
        by websites, apps, online media, and advertisements.CabEasy may use
        cookies and similar technologies for purposes such as: Authenticating
        users Remembering user preferences and settings Determining the
        popularity of content Delivering and measuring the effectiveness of
        advertising campaigns Analyzing site traffic and trends, and generally
        understanding the online behaviors and interests of people who interact
        with our services We may also allow others to provide audience
        measurement and analytics services for us, to serve advertisements on
        our behalf across the Internet, and to track and report on the
        performance of those advertisements. These entities may use cookies, web
        beacons, SDKs, and other technologies to identify your device when you
        visit our site and use our services, as well as when you visit other
        online sites and services.
        <h4>Links To Other Websites</h4>
        Our website may contain links to other websites of interest. However,
        once you have used these links, you should note that we do not have any
        control over that other website. Therefore, we cannot be responsible for
        the protection and privacy of any information which you provide whilst
        visiting such sites and such sites are not governed by this privacy
        statement. You should exercise caution and look at the privacy statement
        applicable to the website in question. We will not sell, distribute or
        lease your personal information to third parties unless we have your
        permission or are required by law to do so. We may use your personal
        information to send you promotional information about third parties
        which we think you may find interesting if you tell us that you wish
        this to happen. If you believe that any information we are holding on
        you is incorrect or incomplete, please write to or email us as soon as
        possible, at the above address. We will promptly correct any information
        found to be incorrect.
        <h4>Disclaimer</h4>
        Every effort has been made to ensure that the information on this site
        is correct and up to date, however, CabEasy does not provide any
        warranty as to the completeness or accuracy of the information and,
        subject to applicable laws, does not accept any liability for damages of
        any kind resulting from the access or use of this website and its
        contents. All information on this site is protected by copyright and
        other intellectual property rights. No images, text or other content
        from this site.
        <h4>Contact Us</h4>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <ul>
          <li>By email: support@cabeasy.in</li>
        </ul>
      </div>
    </>
  );
}

export default PrivacyPolicy;
