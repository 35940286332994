import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { MainContext } from "../Utility";
import { fetchData } from "./StatsData";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Rating from "@mui/material/Rating";
import { yellow } from "@mui/material/colors";

const formatNumberWithCommas = (num) => {
  const [integerPart, decimalPart] = num.toString().split(".");
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
};

const STAT_TITLES = {
  totalBookingAmount: "Total Booking Amount",
  totalBookings: "Total Bookings",
  totalNewSuppliers: "Total Suppliers Added to Network",
  totalNewAgents: "Total Agents Added to Network",
  totalReqsPosted: "Total Requirements Posted",
  totalBidsPosted: "Total Bids Posted",
  overallRating: "Your Rating",
};

const StatsTable = ({ isAgent }) => {
  const { userData } = useContext(MainContext);
  // const [stats, setStats] = useState({
  //   totalBookingAmount: 0,
  //   totalBookings: 0,
  //   totalNewSuppliers: 0,
  //   totalNewAgents: 0,
  //   totalReqsPosted: 0,
  //   totalBidsPosted: 0,
  //   overallRating: 0,
  // });
  const [displayStats, setDisplayStats] = useState({});
  const ratings = userData?.ratings || {};
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  useEffect(() => {
    const getStats = async () => {
      const fetchedStats = await fetchData(userData, isAgent);
      if (fetchedStats) {
        // setStats(fetchedStats);
        setDisplayStats(
          isAgent
          ? {
              totalBookingAmount: fetchedStats.totalBookingAmount,
              totalBookings: fetchedStats.totalBookings,
              totalReqsPosted: fetchedStats.totalReqsPosted,
              overallRating: ratings?.overallRating || null,
              totalNewSuppliers: fetchedStats.totalNewSuppliers,
            }
          : {
              totalBookingAmount: fetchedStats.totalBookingAmount,
              totalBookings: fetchedStats.totalBookings,
              totalBidsPosted: fetchedStats.totalBidsPosted,
              totalNewAgents: fetchedStats.totalNewAgents,
              overallRating: ratings?.overallRating || null,
            }
        )
      }
    };

    getStats();
  }, [userData, isAgent]);

  if(!displayStats) return null;

  return (
    <Box width="100%">
      <Grid container spacing={2}>
        {
          Object.entries(displayStats).map(([key, value]) => {
            if(key != "overallRating" && value <= 0) return null;
            return (<Grid item xs={12} sm={4} key={key}>
              <Box
                p={2}
                m={isMobile ? 0.5 : 1}
                boxShadow={3}
                display="flex"
                flexDirection={isMobile ? "row" : "column"}
                justifyContent="center"
                alignItems="center"
                height={{ xs: 90, sm: 130 }}
                borderRadius={2}
                textAlign={isMobile ? "left" : "center"}
                overflow="hidden"
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    wordWrap: "break-word",
                    maxWidth: isMobile ? "50%" : "100%",
                    whiteSpace: "normal",
                    textTransform: "none",
                  }}
                >
                  {STAT_TITLES[key]}
                </Typography>
                {key === "overallRating" ? (
                  value ? (
                    <Rating
                      value={value}
                      readOnly
                      precision={0.5}
                      style={{
                        marginLeft: isMobile ? "auto" : 0,
                        marginTop: isMobile ? 0 : 10,
                      }}
                    />
                  ) : (
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      style={{
                        wordWrap: "break-word",
                        maxWidth: isMobile ? "45%" : "100%",
                        marginLeft: isMobile ? "auto" : 0,
                        marginTop: isMobile ? 0 : 10,
                        textTransform: "none",
                      }}
                    >
                      Rating will be calculated after your first booking
                    </Typography>
                  )
                ) : (
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      wordWrap: "break-word",
                      maxWidth: "100%",
                      marginLeft: isMobile ? "auto" : 0,
                      marginTop: isMobile ? 0 : 10,
                      textTransform: "none",
                    }}
                  >
                    {key === "totalBookingAmount"
                      ? formatNumberWithCommas(value) + " ₹"
                      : value}
                  </Typography>
                )}
              </Box>
            </Grid>)
          } 
        )}
      </Grid>
    </Box>
  );
};

export default StatsTable;
