import { Link } from 'react-router-dom';
import Typography from "@mui/material/Typography";

 const CopyrightFooter = (props)=> {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        <Link color="text.secondary" href="/">
          CabEasy
        </Link>{" "}
        ©
        {new Date().getFullYear()}. All Rights Reserved.
      </Typography>
    );
  }

  export default CopyrightFooter
