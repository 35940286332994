import { useState, useEffect, useContext } from "react";
import { getToken, onMessage } from "firebase/messaging";
import { setDoc, collection, doc } from "firebase/firestore";

import { db, messaging } from "../firebaseConfig";
import ViewRequirements from "../viewRequirements/index.js";
import { MainContext } from "../Utility";
import MyDailog from "../Commons/myDailog";
import Activities from "../Activities/index.js";
import DashStatistics from "../DashStatistics/index.js";
import PostReq from "../postReq/index.js";

const Mainpage = () => {
  const { isLoggedIn, userData, isUserAgent } = useContext(MainContext); //useState(JSON.parse(localStorage.getItem('user')));
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [show, setShow] = useState(false);
  let { phone = null } = userData || {};

  const getCurrentFcmToken = () => {
    getToken(messaging, {
      vapidKey:
        "BCA51V4rpObaFuFHE1hN0BLZqDXlRiLRN9tajikXfPY4HwqKrFXQqkj2xb6cyGis0__5BBaFEI00rVULqIwjUYc",
    })
      .then(async (currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          console.log("FCM registration token------------ ", currentToken);
          localStorage.setItem("notification-token", currentToken);
          // let docRef = await addDoc(collection(db, "fcmClientTokens"), {token: currentToken});
          let docRef = doc(collection(db, "fcmClientTokens"), phone);
          await setDoc(docRef, { token: currentToken });
          // ...
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one.----------"
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("FCM registration token Error ------------- ", err);
        // ...
      });
  };

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });

  useEffect(() => {
    console.log("Apphome render", phone);
    if (!phone) return;
    getCurrentFcmToken();
    onMessageListener()
      .then((payload) => {
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
        setShow(true);
        console.log(payload);
      })
      .catch((err) => console.log("failed: ", err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  if (!isLoggedIn) {
    return (window.location.href = "/");
  }

  return (
    <>
      <>
        <Activities />
        <DashStatistics isAgent={isUserAgent} />
        { isUserAgent && (<PostReq />)}
      </>
      <MyDailog showDailog={show} {...notification} />
    </>
  );
};

export default Mainpage;
