import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";

import { FAQ_DATA } from "./faq-data";
import AppSubHeader from "../Commons/appSubHeader";

export default function FaqSection() {
  return (
    <>
      <Helmet>
        <title>CabEasy FAQs: Check for commonly asked questions</title>
        <link rel="canonical" href="https://cabeasy.in/faq" />
      </Helmet>
      <AppSubHeader name="Frequently Asked Queastions"/>
      {FAQ_DATA.map((faq, i) => {
        return (
          <Accordion id={i}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight={500}>{faq.title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{background:'#ffeba0'}}>
              <Typography>{faq.description}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}
