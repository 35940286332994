import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import parse from 'html-react-parser';

// import "./bookingCard.css";
import SnackbarMsg from "../../Commons/snackbarMsg";
import { copyTextToClipboard } from "../../Utility";
import { PRIORITY_OPT, PRIORITY_BOOK, PRIORITY_TEST, PRIORITY_FLEX, PRIORITY_COLOR } from "../../Constants";

const DubaiReqCardIndex = ({ reqData = {}, itemId: id = "-" }) => {
  const [openSnack, setOpenSnack] = useState(false);
  const {
    destination,
    pax,
    childPax,
    noOfNights,
    transferType,
    pickUp,
    dropOff,
    finalCompsOpts = [],
    startDate,
    bids = [],
    trackingId,
    otherInfo,
    priority,
  } = reqData;

  const [expanded, setExpanded] = React.useState(true);
  const [compsOpen, setCompsOpen] = React.useState(false);

  const handleExpandClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCompsOpen(false);
    setExpanded(!expanded);
  };

  const handleShowComponents = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(false);
    setCompsOpen(!compsOpen)
  }

  const handleIdClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    copyTextToClipboard(`trackingId-${trackingId}`);
    setOpenSnack(true);
  };

  const bidsCount = bids?.length?.toString();
  return (
    <>
      {openSnack && (
        <SnackbarMsg
          open={openSnack}
          message={"Req id copied!"}
          onClose={() => setOpenSnack(false)}
        />
      )}
      <span style={{ "display": "none" }} id={`trackingId-${trackingId}`}>{trackingId || "-"}</span>

      <Card sx={{ maxWidth: 545, margin: "auto", backgroundColor: '#fdfbee99' }}>
        <div style={{ display: "flex" }}>

          <div id="tracking-id" style={{ padding: "4px 0px 2px 8px", width: "60%", textAlign: "left" }}>
            {trackingId && (
              <Chip
                sx={{ cursor: "pointer", textAlign: "left" }}
                variant="outlined"
                size="small"
                className="singleline-ellipsis"
                label={<span id={trackingId}>{trackingId || "-"}</span>}
                icon={<ContentCopyIcon onClick={handleIdClick} />}
              />
            )}
          </div>
          <div style={{ width: "40%", marginRight: "3px", textAlign: "right" }}>
            {priority && (<Chip
              variant="outlined"
              size="small"
              className="singleline-ellipsis"
              label={PRIORITY_OPT[priority]}
              icon={<InfoIcon  fontSize="8px" color={PRIORITY_COLOR[priority]}  />}
              sx={{
                textAlign: "right",
                fontSize:"9px",
                backgroundColor:PRIORITY_COLOR[priority],
                color:"#f9f5d2"
              }}

            />)}
          </div>
        </div>
        <CardHeader
          sx={{ paddingBottom: 0, paddingTop: "3px" }}
          action={
            <Box sx={{ padding: 1 }}>
              <Badge
                badgeContent={bidsCount || "0"}
                showZero={true}
                color="primary"
              >
                <Typography variant="body1" color="text.primary"><b>Bids</b></Typography>
              </Badge>
            </Box>
          }
          title={
            <Stack
              spacing={{ xs: 1, sm: 1 }}
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Typography variant="body2" color="text.primary">
                <small>{destination}</small>
              </Typography>
              <Typography variant="body2" color="text.primary">
                <small>{`${noOfNights} N`}</small>
              </Typography>
              <Typography variant="body2" color="text.primary">
                {" "}
                <small>{`${pax || 0} Pax`}</small>{" "}
              </Typography>
              <Typography variant="body2" color="text.primary">
                {" "}
                <small>{`${childPax || 0} Child`}</small>{" "}
              </Typography>
              <Typography variant="body2" color="text.primary">
                <small>{format(new Date(startDate), "dd-MMMM-yyyy") || ""}</small>
              </Typography>
            </Stack>
          }
          subheader={
            <Stack
              divider={<Divider orientation="vertical" flexItem />}
              spacing={{ xs: 1, sm: 1 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
            >
              <Typography variant="body2" color="text.primary">
                <small>{pickUp?.label || ''}</small>
              </Typography>
              <Typography variant="body2" color="text.primary">
                <small>{dropOff?.label || ''}</small>
              </Typography>
              {/** <Typography className="singleline-ellipsis booking-card-subheader"
                   variant="body2" color="text.primary"
                >
                  <small>{cabType || ""}</small>
                </Typography> **/}
            </Stack>
          }
        />

        <Divider sx={{ paddingTop: 1 }} />
        {/** <CardContent sx={{ paddingTop: "2px" }}>
            <Typography
              variant="body2"
              color="primary"
              className="multine-ellipsis"
              sx={{ paddingTop: "5px", textAlign: "center" }}
            >
              <b>{route || "-"}</b>
            </Typography>
          </CardContent> **/}

        <CardActions disableSpacing>
          <Button size="small" sx={{ fontSize: 12 }}
            variant="contained" onClick={handleShowComponents}
            endIcon={compsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          >
            Components
          </Button>
          {otherInfo && (
            <Button
              variant="secondary"
              size="small"
              sx={{ marginLeft: "auto", fontSize: 12 }}
              endIcon={expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              onClick={handleExpandClick}
            >
              customization
            </Button>
          )}
        </CardActions>
        <Collapse id="finalCompsOpts" in={compsOpen} timeout="auto" unmountOnExit>
          <CardContent>
            {
              finalCompsOpts.map((sec) => {
                let { name, types = [] } = sec;
                if (types.length == 0) return null;
                return (<>
                  <Typography variant="body1">{name}:</Typography>
                  <Box ml={1}>
                    {
                      types.map((item) => {
                        return (<Box sx={{ display: 'flex' }}>
                          <Typography variant="body2" color="text.primary"><small>{item.name} - </small></Typography>
                          &nbsp;
                          <Typography variant="body2" color="primary"><small>{item.rateType}</small></Typography>
                        </Box>)
                      })
                    }
                  </Box>
                </>)
              })
            }
            <Typography paragraph>

            </Typography>
          </CardContent>
        </Collapse>

        <Collapse id="otherInfo" in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              <small>{parse(otherInfo)}</small>
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default DubaiReqCardIndex;
