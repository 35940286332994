import "./OfficialWALanding.css";

const OfficialWALandingPage = () => {

	return (<div class="container" style={{display: "flex"}}>
		<div style={{display: "flex", flexDirection: "column", width: "50%", margin: '10px'}}>
	        <h1>Welcome to CabEasy's<br />Official WhatsApp<br />Business Account!</h1>
	        <button class="cta-button">Chat with us</button>
	    </div>
	    <div style={{display: "flex", flexDirection: "column", margin: '10px'}}>
	        <div class="features">
	            <div class="feature">
	                <h2>Get PBO Reminders:</h2>
	                <p>Post requests and receive instant confirmations.</p>
	            </div>
	            <div class="feature">
	                <h2>Get Real-Time Updates:</h2>
	                <p>Stay informed with real-time notifications for all your bookings.</p>
	            </div>
	            <div class="feature">
	                <h2>Receive Support:</h2>
	                <p>Access quick and easy customer support right from your WhatsApp inbox.</p>
	            </div>
	        </div>
	        <div class="illustration">
	            <img src="https://firebasestorage.googleapis.com/v0/b/persuasive-ego-377212.appspot.com/o/website%2FScreenshot%202024-08-31%20141012.jpg?alt=media&token=fc1a3c57-5136-4718-96f8-c46d72c4b27d" 
	            	alt="Illustration of person using computer"
	            />
	        </div>
	    </div>
    </div>)
}

export default OfficialWALandingPage;