export const FAQ_DATA = [
  {
    title: "What is cabeasy?",
    description:
      "We are your reliable partner in the world of transportation. With years of experience and a commitment to service excellence, we have established ourselves as a trusted name in the industry. Your ultimate destination for all your taxi and commuting needs. We take pride in offering safe and dependable 24-hour taxi services, catering to both short and long-distance journeys.",
  open:true
    },
  {
    title: "Where can I hire a cab?",
    description:
"We offer chauffeured vehicles and related services throughout India"
  },
  {
    title: "For how long can I hire a cab?",
    description:
      "We accommodate everything from airport transfers to multi-week travel rentals.",
  },
  {
    title: "Are any common charges excluded?",
    description:
      "No, our quotes include parking, tolls and driver expenses. There are no night-time or waiting charges.",
  },
  {
    title: "What if I need to cancel my booking?",
    description:
      "You will be refunded in full so long as you cancel at least 24 hours before your pickup time or as instructed by the support team",
  },
  {
    title: "Are there any mileage limits?",
    description:
      "Within the cities in your itinerary, there are no mileage limits. Itineraries are not required in some places.",
    disabled: true,
  },
];
