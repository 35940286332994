import React, { useState, useEffect, useContext } from "react";
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { format } from "date-fns";
import { Helmet } from "react-helmet";

import AgentView from "./agentView";
import SupplierView from "./supplierView";
import { MainContext, getDateAndTimeDiff, devConsolelog } from "../Utility";
import { REQ_EXPIRE_TIMEOUT_DAYS } from "../Constants";
import { db, auth } from "../firebaseConfig";

const ActiveReqs = () => {
  // const userData = JSON.parse(localStorage.getItem("user"));
  // const isUserAgent = Boolean((userData || {}).isAgent);
  const { userData, isUserAgent } = useContext(MainContext);
  const [reqs, setReqs] = useState([]);
  const [loading, setLoading] = useState(false);

  const dataStates = {
    reqs,
    setReqs,
    loading,
  };

  useEffect(() => {
    const fetchUserIdToken = async () => {
      try {
        auth.onAuthStateChanged(async (user) => {
          if (user) {
            // user.getIdToken().then(function(data) {
            //   console.log(data)
            // });
            let signedInIdToken = await auth.currentUser.getIdToken(
              /* forceRefresh */ true,
            );
            console.log("signedInIdToken ", signedInIdToken);
          }
        });
      } catch (e) {
        console.log("signedInIdToken error ", e);
      }
    };

    fetchUserIdToken();
  }, []);

  const checkExpiredReqs = async (data = []) => {
    console.log("getDateAndTimeDiff checkExpiredReqs", data);
    let filteredReqs = [...data];
    data.forEach(async (item, i) => {
      let daysDiffVal = getDateAndTimeDiff(item.createdAt);

      devConsolelog(
        "getDateAndTimeDiff -",
        item.destination,
        format(new Date(item.startDate), "dd-MMMM-yyyy"),
        daysDiffVal.days,
        daysDiffVal.days < 0 &&
          Math.abs(daysDiffVal.days) > REQ_EXPIRE_TIMEOUT_DAYS,
      );

      if (
        daysDiffVal.days < 0 &&
        Math.abs(daysDiffVal.days) > REQ_EXPIRE_TIMEOUT_DAYS
      ) {
        const docRef = doc(db, "agentRequirements", item.id);
        await updateDoc(docRef, {
          status: "expired",
        });
        filteredReqs.splice(i, 1);
      }
    });
    // sort based on createdAt
    setReqs(
      filteredReqs.sort(
        (a, b) => Number(b.createdAt || 0) - Number(a.createdAt || 0),
      ),
    );
  };

  const compFuncs = {
    getAgentActiveReqs: async () => {
      setLoading(true);
      const q = query(
        collection(db, "agentRequirements"),
        where("status", "==", "active"),
        where("agentId", "==", userData.phone),
        // orderBy("createdAt", "desc")
      );

      const unsubscribe = await onSnapshot(
        q,
        (snapshot) => {
          // ...
          // if (!snapshot.exists()) return;
          let data = snapshot.docs;
          console.log("snapshot", data, snapshot);
          data = data.map((i) => {
            return { ...i.data(), id: i.id };
          });
          setReqs(
            data.sort(
              (a, b) => Number(b.createdAt || 0) - Number(a.createdAt || 0),
            ),
          );
          checkExpiredReqs(data);
        },
        (error) => {
          console.log("view req error", error);
        },
      );

      return unsubscribe;
    },

    getAllActiveReqs: async () => {
      setLoading(true);
      // console.log('get supp reqs ', userData.destinations)
      const q = query(
        collection(db, "agentRequirements"),
        where("status", "==", "active"),
        where("destination", "in", userData.destinations),
        // orderBy("createdAt", "desc")
      );

      const unsubscribe = await onSnapshot(
        q,
        (snapshot) => {
          // ...
          // if (!snapshot.exists()) return;
          let data = snapshot.docs;
          console.log("snapshot supplier", data, snapshot);
          data = data.map((i) => {
            return { ...i.data(), id: i.id };
          });
          // sort based on createdAt
          setReqs(
            data.sort(
              (a, b) => Number(b.createdAt || 0) - Number(a.createdAt || 0),
            ),
          );

          // setReqs(data);
          checkExpiredReqs(data);
        },
        (error) => {
          console.log("view req error", error);
        },
      );

      return unsubscribe;
    },
  };

  console.log("view reqs render ", dataStates);
  return (
    <>
      <Helmet>
        <title>CabEasy Active Requirements: Track your active requirements</title>
        <link rel="canonical" href="https://cabeasy.in/my-reqs" />
      </Helmet>

      {isUserAgent ? (
        <AgentView
          userData={userData}
          {...dataStates}
          {...compFuncs}
          setLoading={setLoading}
        />
      ) : (
        <SupplierView
          userData={userData}
          {...dataStates}
          {...compFuncs}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default ActiveReqs;
