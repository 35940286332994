import React, { useEffect } from 'react';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/icons-material/Link";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

import { KYC_FAQ_DATA } from "../Faq/kyc-faq-data";
import { KYC_FORM_LINK } from "../Constants.js";

const KYCFaqSection = () => {
  return (
    <>
      {KYC_FAQ_DATA.map((faq, i) => {
        return (
          <Accordion id={i}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight={500}>{faq.title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "#ffeba0" }}>
              <Typography>{faq.description}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

const KycPending = () => {
  const userData = JSON.parse(localStorage.getItem("user") || null);
  const isUserAgent = Boolean((userData || {}).isAgent);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      localStorage.setItem("kycPopUpTime", Date.now());
    } catch (e) {
      console.log("kycPopUpTime");
    }
  }, [])

  return (
      <Container>
        <center>
          <h5>Thank you for registering with CabsEasy.</h5>

          <p>
            To insure the security of your account and comply with regulatory
            requirments, we need to verify your identity.
            <b>It will be done in 5mins!</b>
          </p>
          <p>Please login again when you receive the kyc verification email. 
            <b>The link will be availble in the email.</b> 
          </p>
          <div style={{ margin: "30px 0px" }}>
            <KYCFaqSection />
          </div>

          <div style={{ maxWidth: "300px" }}>
            <Button autoFocus
              fullWidth
              sx={{ mr: 1, flexGrow: 1, m: "8px 0px" }}
              variant="contained"
              onClick={() =>
                window.open(
                  KYC_FORM_LINK,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              endIcon={<Link />}
            >
              Complete KYC
            </Button>
          </div>
          {
            (isUserAgent && window.location.pathname == "/kyc-pending" ) && (<div style={{ maxWidth: "300px" }}>
              <Button autoFocus
                size="small"
                sx={{ mr: 1, flexGrow: 1, m: "8px 0px" }}
                variant="outlined"
                onClick={() =>
                  navigate("/home")
                }
              >
                Skip For now
              </Button>
            </div>)
          }
        </center>
      </Container>
  );
};

export default KycPending;
