import AccountCircle from "@mui/icons-material/AccountCircle";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CopyrightFooter from "../Commons/copyright-footer";
import Navbar from "../Navbar";

export default function UnprotectdedAppLayout({ children, showNavBar = true }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {/** <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <a href="/" style={{ color: "#000" }}>
                CabEasy
              </a>
            </Typography>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Login</MenuItem>
                <MenuItem onClick={handleClose}>Join CabEasy</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar> **/}
        {showNavBar && <Navbar />}
        <Container maxWidth="md" fixed >
          <Box component="main" sx={{ pt: 9, pb: 2 }}>
            {children}
          </Box>
        </Container>
      </Box>
      <footer
        style={{ background: "inherit", textAlign: "center", fontSize: "10px" }}
      >
        <CopyrightFooter sx={{ mt: 5 }} />
      </footer>
    </>
  );
}
