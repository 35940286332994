export const KYC_FAQ_DATA = [
    {
      title: "What type of documents are required?",
      description:
        "For business Know Your Customer (KYC) compliance, typically the required documents include: (1). PAN/Aadhar Card (2). GST documents(if any) (3). Business Registration Documents etc"
    },
    {
      title: "How much time this KYC Verification Process going to take after submitting the documents?",
      description:
        "The time it takes to complete the business Know Your Customer (KYC) process can vary widely depending on several factors, including the complexity of the business, the regulatory environment in your jurisdiction, and the efficiency of the institution or service provider conducting the KYC. In general, it can take anywhere from a 24hr to 2-3 days to complete the KYC process at our portal",
    },
    {
      title: "How will I know if the process is completed?",
      description: "You will receive a verification email once we have successfully reviewed all of your submitted documents. After that, you can start using CabEasy services. However, in case your verification process is not approved, we will promptly notify you and assist you in completing it as quickly as possible."
    },
  ];
  