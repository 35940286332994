import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import {
  collection,
  doc,
  setDoc,
  addDoc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";

import { PHONE_FILTER_REGEX } from "./Constants";
import { db } from "./firebaseConfig";

export const MainContext = React.createContext({});

export const AutocompleteInput = ({
  label = "Input",
  onChange = () => {},
  values = [],
  multiSelect = true,
  ...restProps
}) => {
  const MENU_ITEM_HEIGHT = 48;
  const MENU_ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: MENU_ITEM_HEIGHT * 4.5 + MENU_ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="destinations">{label}</InputLabel>
      <Select
        label="destinations"
        id="destinations"
        multiple={multiSelect}
        fullWidth
        value={values}
        defaultValue={"active"}
        onChange={onChange}
        placeholder="Filter by booking status"
        input={<OutlinedInput label="Name" />}
        MenuProps={MenuProps}
        {...restProps}
      >
        {values.map((name) => (
          <MenuItem
            key={name}
            value={name}
            defaultChecked={"active"}
            defaultValue={"active"}
            // style={getStyles(name, personName, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const getDateAndTimeDiff = (expireDate) => {
  if (!expireDate) return {};

  const datetime = new Date(expireDate).getTime();
  const now = new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000;

  let milisecDiff = null;

  milisecDiff = datetime - now;
  // if (datetime < now) {
  //    milisecDiff = now - datetime ;
  // }else{
  // }

  const days = Math.floor(milisecDiff / 1000 / 60 / (60 * 24));
  const hours = Math.floor(milisecDiff / 1000 / 60 / 60);
  milisecDiff -= hours * 1000 * 60 * 60;
  const minutes = Math.floor(milisecDiff / 1000 / 60);
  milisecDiff -= minutes * 1000 * 60;
  const seconds = Math.floor(milisecDiff / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const copyTextToClipboard = (
  id = null,
  copyTextAreaContainer = "root",
  cb,
  type = "noninput"
) => {
  if (!copyTextAreaContainer) copyTextAreaContainer = "root";
  if (type == "input") {
    // only for input fields
    let text = document.getElementById(id);
    text.select();
    text.setSelectionRange(0, 99999);
    document.execCommand("copy");
  } else {
    // only for non input fields
    var text = document.getElementById(id).innerText;
    var elem = document.createElement("textarea");
    document.getElementById(copyTextAreaContainer).appendChild(elem);
    elem.value = text;
    elem.select();
    elem.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.getElementById(copyTextAreaContainer).removeChild(elem);
  }

  if (cb) cb();
  // setViewSnackbar({message:'Link Copied!', type:'success'})
};

export const convertFirstLetterIntoUppercase = (str = "") => {
  if (!str) return;
  str = str.charAt(0).toUpperCase() + str.slice(1);
  return str;
};

export const AmountWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
};

export const devConsolelog = (val, ...others) => {
  if (val && process.env.NODE_ENV === "development")
    console.log(val, ...others);
};

export const isEmptyObject = (obj) => {
  for (let name in obj) {
    if (obj.hasOwnProperty(name)) {
      return false;
    }
  }
  return true;
};

export const IsEmail = (email) => {
  var regex =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!regex.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const useDetectMob = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    setIsMobile(
      toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      })
    );
  }, []);

  return isMobile;
};

export const getStatusColor = (status) => {
  const cardStatus = status.toLowerCase();
  switch (cardStatus) {
    case "completed":
      return "success";
    case "pending":
      return "primary";
    case "failed":
      return "warning";
    case "active":
      return "primary";
    default:
      return "default";
  }
};

export const sendEmail = async (data = null) => {
  console.log("sendEmail ", data);
  if (!data) return;
  let {
    to = null,
    name = null,
    from = null,
    template = null,
    subject = null,
    text = null,
    html = null,
  } = data;

  let mailDocData = {
    to,
    // message: {
    //   subject
    // },
    template,
    createdAt: Date.now(),
  };

  if (
    process.env.NODE_ENV == "development" &&
    template.name != "req_created_agent"
  )
    return;

  // if(text) mailDocData['message']['text'] = text;
  // else if(text) mailDocData['message']['html'] = html;
  console.log("sendEmail doc update ", mailDocData);
  // return mailDocData;
  let docRef = await addDoc(collection(db, "mails"), mailDocData);
};

export const getUserDetails = async (userNumber = "") => {
  if(!userNumber) return;
  const docSnap = await getDoc(doc(db, "userDetails", userNumber?.trim()));
  console.log(
    "getBookingPartnerUserDetails ",
    docSnap.exists(),
    docSnap.data()
  );
  if (docSnap.exists()) {
    console.log("booking user data ", docSnap.data());
    // setBookingPartnerDetails(docSnap.data());
    return docSnap.data();
  } else {
    console.error(`user details not forund for ${userNumber}`);
    return null;
  }
};

export const getComplaintDocId = (phone, issueTrackingId) =>
  `${phone}_${issueTrackingId}`;

export const checkIfBothMarkedBookingComplete = (bookingData = {}) => {
  console.log(
    "checkIfBothMarkedBookingComplete ",
    bookingData.agentMarkComplete && bookingData.supplierMarkComplete,
    !bookingData && isEmptyObject(bookingData),
    bookingData
  );
  if (!bookingData && isEmptyObject(bookingData)) return false;
  return bookingData.agentMarkComplete && bookingData.supplierMarkComplete;
};

export const queryStringToObject = (url) =>
  [...new URLSearchParams(url.split("?")[1])].reduce(
    (a, [k, v]) => ((a[k] = v), a),
    {}
  );

export const filterPhoneNo = (str) => {
  if (typeof str !== "string") return str;
  // find and filter potential phone nos
  return str.replace(PHONE_FILTER_REGEX, " ");
};

export const addUserActivity = async (message, cta, phone) => {
  if (!phone) {
    console.error("Phone number is required to create a document.");
    return;
  }

  const notification = {
    message: message,
    cta: cta,
    createdAt: Date.now(),
  };

  try {
    const docRef = doc(db, "activities", phone);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, {
        notifications: arrayUnion(notification),
      });
    } else {
      const docData = {
        notifications: [notification],
      };
      await setDoc(docRef, docData);
    }

    console.log("Notification added successfully!");
  } catch (error) {
    console.error("Error adding notification:", error);
  }
};
