import React from "react";
import Box from "@mui/material/Box";

export const StatsContainer = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" my={2}>
      {children}
    </Box>
  );
};
